// ProvinceService.ts
import Province from "../interfaces/Province";
import District from "../interfaces/District";

const API_URL = "https://vapi.vnappmob.com/api/province/";

async function getAllProvinces(): Promise<Province[]> {
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error("Failed to fetch provinces");
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error fetching provinces:", error);
    return [];
  }
}

async function getDistrictsByProvinceId(
  provinceId: number
): Promise<District[]> {
  try {
    const response = await fetch(`${API_URL}district/${provinceId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch districts");
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error fetching districts:", error);
    return [];
  }
}

export { getAllProvinces, getDistrictsByProvinceId };
