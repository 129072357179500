// /src/redux/slices/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  role: string;
  selectedTab: string;
  selectedCity: string;
  provinceId: number;
  showSidebar: boolean;
}

const authSlice: any = createSlice({
  // Add type annotation 'any'
  name: "auth",
  initialState: {
    role: localStorage.getItem("role") || "", // Lấy giá trị từ localStorage nếu có
    selectedTab: "dashboard",
    selectedCity: "ho chi minh",
    provinceId: 79,
    showSidebar: false,
  } as AuthState,
  reducers: {
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
      localStorage.setItem("role", action.payload);
    },
    setSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
    setSelectedCity: (state, action: PayloadAction<string>) => {
      state.selectedCity = action.payload;
    },
    setProvinceId: (state, action: PayloadAction<number>) => {
      state.provinceId = action.payload;
    },
    showSidebarReducer: (state = authSlice.initialState, action) => {
      return {
        ...state,
        showSidebar: !state.showSidebar,
      };
    },
  },
});

export const {
  setRole,
  setSelectedTab,
  setSelectedCity,
  setProvinceId,
  showSidebarReducer,
} = authSlice.actions;
export default authSlice.reducer;
