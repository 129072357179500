function BackgroundHomepage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100vh"
      version="1.1"
      viewBox="0 0 900 600"
      style={{ width: "100%", height: "100%" }}
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        fill="#efefef"
        d="M0 109l82-36 82-6 81 72 82-18 82 6 82-6h82l82 36 81-12 82-6 82-36V0H0z"
      ></path>
      <path
        fill="#f3f3f3"
        d="M0 157l82 12 82-6 81 54 82-30 82 12 82 12 82-12 82-6 81-6 82-12 82-24v-50l-82 36-82 6-81 12-82-36h-82l-82 6-82-6-82 18-81-72-82 6-82 36z"
      ></path>
      <path
        fill="#f7f7f7"
        d="M0 229l82 18 82 36 81 12 82 6 82-60 82 90 82-66 82 12 81-48 82 66 82-66v-80l-82 24-82 12-81 6-82 6-82 12-82-12-82-12-82 30-81-54-82 6-82-12z"
      ></path>
      <path
        fill="#fbfbfb"
        d="M0 397l82 12 82-18 81 102 82-72 82 54 82 12 82-12 82 24 81-150 82 54 82-84v-92l-82 66-82-66-81 48-82-12-82 66-82-90-82 60-82-6-81-12-82-36-82-18z"
      ></path>
      <path
        fill="#fff"
        d="M0 601h900V317l-82 84-82-54-81 150-82-24-82 12-82-12-82-54-82 72-81-102-82 18-82-12z"
      ></path>
    </svg>
  );
}

export default BackgroundHomepage;
