import { Box, useColorModeValue, Text, Center } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AuthState } from "../redux/slices/authSlice";
function Footer() {
  const { showSidebar } = useSelector(
    (state: { auth: AuthState }) => state.auth
  );
  return (
    <Box
      minH={2}
      py={2}
      borderTop="1px"
      borderTopColor={useColorModeValue("gray.200", "gray.700")}
    >
      <Center marginLeft={`${showSidebar ? "240px" : "80px"}`}>
        <Text fontSize={"9.5px"}>
          © Research project using Machine Learning to predict house prices,
          conducted by Tran Thanh Binh (K204061426)
        </Text>
      </Center>
    </Box>
  );
}

export default Footer;
