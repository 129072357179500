import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h1"
        size="3xl"
        bgGradient="linear(to-r, green.400, green.600)"
        backgroundClip="text"
        fontSize="50px"
      >
        404
      </Heading>
      <Text fontSize="30px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={"gray.500"} mb={6}>
        The page you&apos;re looking for does not seem to exist
      </Text>
      <Link to="/">
        <Button size="sm" variant="outline">
          Back
        </Button>
      </Link>
    </Box>
  );
}
