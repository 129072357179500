import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Stack,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";
import { useSelector } from "react-redux"; // Import useSelector
import { FaWandMagicSparkles } from "react-icons/fa6";
import PredictionData from "../interfaces/PredictionForm";
import { vietnameseToSpecialCondition } from "../utils/helper";
import { useQuery } from "react-query";
import { AuthState } from "../redux/slices/authSlice";
import { getDistrictsByProvinceId } from "../services/ProvinceService";
import { predictPrice } from "../services/HouseService";

const HousePricePrediction: React.FC = () => {
  const [district, setDistrict] = useState("");
  const [houseType, setHouseType] = useState("");
  const [nearbyMarkets, setNearbyMarkets] = useState(false);
  const [nearbySchools, setNearbySchools] = useState(false);
  const [nearbyHospitals, setNearbyHospitals] = useState(false);
  const [hasAirConditioning, setHasAirConditioning] = useState(false);
  const [desiredRoomArea, setDesiredRoomArea] = useState(0);
  const [predictedPrice, setPredictedPrice] = useState<number | null>(null);
  const [predictionParams, setPredictionParams] =
    useState<PredictionData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const provinceId = useSelector(
    (state: { auth: AuthState }) => state.auth.provinceId
  );

  const { data: districts, isLoading: districtsLoading } = useQuery(
    ["districts", provinceId],
    () => getDistrictsByProvinceId(provinceId),
    { enabled: !!provinceId }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params: PredictionData = {
          room_area: desiredRoomArea,
          nearby_market: nearbyMarkets ? 1 : 0,
          nearby_school: nearbySchools ? 1 : 0,
          nearby_hospital: nearbyHospitals ? 1 : 0,
          have_condition: hasAirConditioning ? 1 : 0,
          district: vietnameseToSpecialCondition(district).toLowerCase(),
          housing_type: houseType.toLowerCase(),
        };
        setPredictionParams(params);
      } catch (error: any) {
        console.error("Error fetching post detail:", error);
        setError(error.message); // Set error message
      }
    };
    fetchData();
  }, []); // Include houseId in the dependencies array
  const fetchPredictedPrice = async () => {
    try {
      if (predictionParams) {
        const price = await predictPrice(predictionParams);
        setPredictedPrice(price);
        setError(null); // Clear error
      } else {
        setError("Prediction parameters are missing");
      }
    } catch (err: any) {
      console.error("Error:", err);
      setError(err.message);
    }
  };
  return (
    <Box p={6} w="100%" overflow="hidden">
      <Heading mb={4} fontSize="2xl" fontWeight="600">
        Post Rental Listing
      </Heading>
      <Box>
        <FormControl id="title" isRequired mb={4}>
          <FormLabel>Post Title</FormLabel>
          <Input focusBorderColor="brand.500" />
        </FormControl>
      </Box>
      <Box display={"flex"}>
        <FormControl id="room_type" isRequired mb={4} mr={4}>
          <FormLabel>Room Type</FormLabel>
          <Select
            placeholder="Select House Type"
            focusBorderColor="brand.500"
            onChange={(e) => setHouseType(e.target.value)}
          >
            <option key={"1"} value={"apartment"}>
              Apartment
            </option>
            <option key={"2"} value={"ordinary_housing"}>
              Ordinary Housing
            </option>
            <option key={"3"} value={"boarding_house"}>
              Boarding House
            </option>
          </Select>
        </FormControl>
        <FormControl id="area" isRequired mb={4}>
          <FormLabel>Room Area (m²)</FormLabel>
          <Input
            focusBorderColor="brand.500"
            min={0}
            onChange={(e) => setDesiredRoomArea(Number(e.target.value))}
          ></Input>
        </FormControl>
      </Box>
      <Box display="flex" mb={4}>
        <FormControl id="address_city" isRequired>
          <FormLabel>City</FormLabel>
          <Select
            placeholder="Select City"
            focusBorderColor="brand.500"
            name="address_city"
          >
            <option value="option1">Ho Chi Minh</option>
            <option value="option2">Ha Noi</option>
            <option value="option3">Option 3</option>
          </Select>
        </FormControl>
        <FormControl ml={4} isRequired>
          <FormLabel>District</FormLabel>
          <Select
            placeholder="Select District"
            focusBorderColor="brand.500"
            // name="address_district"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
          >
            {districts?.map((district) => (
              <option key={district.district_id} value={district.district_name}>
                {district.district_name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl id="price" isRequired mb={4}>
          <FormLabel>Price</FormLabel>
          <Box display={"flex"}>
            <Input
              focusBorderColor="brand.500"
              width={"30%"}
              value={predictedPrice ? predictedPrice?.toString() : ""}
              onChange={(e) => {
                setPredictedPrice(Number(e.target.value));
              }}
            />
            <Button
              ml={4}
              leftIcon={<FaWandMagicSparkles />}
              onClick={fetchPredictedPrice}
            >
              Suggest Price
            </Button>
          </Box>
        </FormControl>
      </Box>
      <Box>
        <FormControl id="description" mb={4}>
          <FormLabel>Description</FormLabel>
          <Textarea focusBorderColor="brand.500" />
        </FormControl>
      </Box>
      <FormControl mb={4}>
        <FormLabel>Surrounding Information</FormLabel>
        <Stack pl={2} mt={1} spacing={1}>
          <Checkbox
            colorScheme="brand"
            name="schoolNearby"
            onChange={(e) => setNearbySchools(e.target.checked)}
          >
            Nearby School
          </Checkbox>
          <Checkbox
            colorScheme="brand"
            name="hospitalNearby"
            onChange={(e) => setNearbySchools(e.target.checked)}
          >
            Nearby Hospital
          </Checkbox>{" "}
          <Checkbox
            colorScheme="brand"
            name="supermarketNearby"
            onChange={(e) => setNearbyMarkets(e.target.checked)}
          >
            Nearby Market/Supermarket
          </Checkbox>
        </Stack>
      </FormControl>
      <Button mt={5} colorScheme="brand" isLoading loadingText="Posting...">
        Post
      </Button>
    </Box>
  );
};

export default HousePricePrediction;
