import React, { ReactNode } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import { Flex, Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { AuthState } from "../../redux/slices/authSlice";
interface LayoutDefaultProps {
  children: ReactNode;
}
const DefaultLayout: React.FC<LayoutDefaultProps> = ({ children }) => {
  const { showSidebar } = useSelector(
    (state: { auth: AuthState }) => state.auth
  );
  return (
    <div>
      <Header></Header>
      <Box>
        <Flex paddingTop={"60px"}>
          <Sidebar></Sidebar>
          <Box marginLeft={`${showSidebar ? "240px" : "60px"}`} width={"100%"}>
            {children}{" "}
          </Box>
        </Flex>{" "}
      </Box>
      <Footer></Footer>
    </div>
  );
};

export default DefaultLayout;
