import React, { ReactNode, useEffect, useState } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Image,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import dashboardIcon from "../assets/brand/dashboard.svg";
import postIcon from "../assets/brand/post.svg";
import exploreIcon from "../assets/brand/explore.svg";
import { Link, useLocation } from "react-router-dom";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedTab,
  AuthState,
  showSidebarReducer,
} from "../redux/slices/authSlice";

interface LinkItemProps {
  name: string;
  icon: string;
  to: string;
}

// Import the 'showSidebarReducer' function from the '../redux/slices/authSlice' module

const LinkItems: Array<LinkItemProps> = [
  {
    name: "Dashboard",
    icon: dashboardIcon,
    to: "/detail#dashboard",
  },
  { name: "Post", icon: postIcon, to: "/detail#predict" },
  { name: "Explore", icon: exploreIcon, to: "/detail#explore" },
];

export default function Sidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const showSidebar = useSelector(
    (state: { auth: AuthState }) => state.auth.showSidebar
  );

  const handleToggleSidebar = () => {
    dispatch(showSidebarReducer(showSidebar));
  };
  return (
    <Box
      bgColor={"white"}
      zIndex={11}
      minH="calc(100vh - 60px - 2rem)"
      bg={useColorModeValue("gray.100", "gray.900")}
      position={"fixed"}
    >
      <Box
        position="absolute"
        alignItems="center"
        display="flex"
        background="#ffffff"
        _hover={{
          background: "brand.500",
          cursor: "pointer",
          color: "white",
          borderColor: "white",
        }}
        borderWidth={"1.3px"}
        color={useColorModeValue("gray.400", "gray.700")}
        borderColor={useColorModeValue("gray.400", "gray.700")}
        right="-10px"
        bottom="22px"
        width="22px"
        height="22px"
        borderRadius="50%"
        justifyContent="center"
        onClick={handleToggleSidebar}
      >
        {showSidebar ? <PiCaretLeftBold /> : <PiCaretRightBold />}
      </Box>
      <SidebarContent
        onClose={onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedTab, showSidebar } = useSelector(
    (state: { auth: AuthState }) => state.auth
  );

  useEffect(() => {
    const currentTab = LinkItems.find((link) => link.to === location.pathname)
      ?.name;
    if (currentTab) {
      dispatch(setSelectedTab(currentTab));
      setSelectedTab(currentTab);
    }
  }, [location.pathname, location.hash, dispatch]);

  const role = localStorage.getItem("role");
  const isLeaser = role === "AMHVv7fR76cyi2qYzNJcFHggtpk4LQ";
  const handleLinkClick = (link: LinkItemProps) => {
    dispatch(setSelectedTab(link.name.toLowerCase()));
  };

  return (
    <Box
      paddingTop={4}
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: `${showSidebar ? "240px" : "60px"}` }}
      h={{ base: "full", md: "calc(100vh - 60px)" }}
      {...rest}
    >
      <Flex alignItems="center" mx="0" justifyContent="space-between">
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) =>
        link.name !== "Post" || isLeaser ? (
          <Link
            to={link.to}
            key={link.name}
            onClick={() => handleLinkClick(link)}
          >
            <NavItem
              icon={link.icon}
              isSelected={
                selectedTab === link.name ||
                (link.to.startsWith("/detail#") &&
                  location.hash === `#${link.to.split("#")[1]}`)
              }
            >
              {showSidebar && link.name}
            </NavItem>
          </Link>
        ) : null
      )}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: string;
  children: ReactNode;
  isSelected: boolean;
}
const NavItem = ({ icon, children, isSelected, ...rest }: NavItemProps) => {
  const { showSidebar } = useSelector(
    (state: { auth: AuthState }) => state.auth
  );

  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        marginTop={3}
        align="center"
        px={`${showSidebar ? "3px" : "0"}`}
        py={2}
        mx={`${showSidebar ? "3px" : "4px"}`}
        borderRadius="sm"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "brand.opacity",
          borderLeft: "2px solid",
          borderColor: "brand.500",
        }}
        bg={isSelected ? "brand.opacity" : "transparent"}
        borderLeft={isSelected ? "2px solid" : "none"}
        borderColor={isSelected ? "brand.500" : "transparent"}
        {...rest}
      >
        {icon && (
          <Box
            mr="3"
            ml="2"
            borderRadius={"50%"}
            height={"34px"}
            width={"34px"}
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
            padding={showSidebar ? "8px" : "5px"}
            bg={showSidebar ? "brand.opacity" : "transparent"}
          >
            <Image width="100%" src={icon} />
          </Box>
        )}
        {children}
      </Flex>
    </Box>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<CopyIcon />}
      />
    </Flex>
  );
};
