import {
  createBrowserRouter,
  // createRoutesFromElements,
  // Route,
} from "react-router-dom";
import DefaultLayout from "./pages/layout/DefaultLayout";
import FlexLayout from "./pages/layout/FlexLayout";
import DetailPage from "./pages/Detail";
import NotFound from "./pages/NotFound";
import Dashboard from "./components/Dashboard";
import Welcome from "./pages/Welcome";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <FlexLayout>
        <Welcome />
      </FlexLayout>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "detail",
    element: (
      <DefaultLayout>
        <DetailPage />
      </DefaultLayout>
    ),
  },
]);
export default router;
