import React, { ReactNode } from "react";

interface FlexDefaultProps {
  children: ReactNode;
}
const FlexLayout: React.FC<FlexDefaultProps> = ({ children }) => {
  return (
    <div>
      <>{children}</>
    </div>
  );
};

export default FlexLayout;
