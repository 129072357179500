import PredictionData from "../interfaces/PredictionForm";
import { Post } from "../interfaces/Post";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL_DEV;

interface GetPostsParams {
  page: number;
  perPage: number;
  district?: string;
  houseType?: string;
  nearbyMarkets?: boolean;
  nearbySchools?: boolean;
  nearbyHospitals?: boolean;
  hasAirConditioning?: boolean;
}

// Define the structure of the expected response data
interface PostsResponse {
  posts: Post[];
  total_posts: number;
  total_pages: number;
  current_page: number;
  per_page: number;
}

const getPosts = async ({
  page,
  perPage,
  district,
  houseType,
  nearbyMarkets,
  nearbySchools,
  nearbyHospitals,
  hasAirConditioning,
}: GetPostsParams): Promise<PostsResponse> => {
  try {
    let queryParams = `page=${page}&per_page=${perPage}`;

    if (district) queryParams += `&district=${encodeURIComponent(district)}`;
    if (houseType)
      queryParams += `&housing_type=${encodeURIComponent(houseType)}`;
    if (nearbyMarkets !== undefined)
      queryParams += `&nearby_markets=${nearbyMarkets ? 1 : 0}`;
    if (nearbySchools !== undefined)
      queryParams += `&nearby_schools=${nearbySchools ? 1 : 0}`;
    if (nearbyHospitals !== undefined)
      queryParams += `&nearby_hospitals=${nearbyHospitals ? 1 : 0}`;
    if (hasAirConditioning !== undefined)
      queryParams += `&has_air_conditioning=${hasAirConditioning ? 1 : 0}`;

    const response = await axios.get<PostsResponse>(
      `${BASE_URL}/api/posts?${queryParams}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const getPostById = async (postId: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/posts/${postId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching post by ID:", error);
    throw error;
  }
};

const getHouseWithHighestPrice = async (
  district: string,
  housingType: string
) => {
  try {
    let queryParams = `district=${district}&housing_type=${housingType}`;
    const response = await axios.get(
      `${BASE_URL}/api/highest_price?${queryParams}`
    );
    return response.data.highest_price;
  } catch (error) {
    console.error("Error fetching house with highest price:", error);
    throw error;
  }
};

const getHouseWithLowestPrice = async (
  district: string,
  housingType: string
) => {
  try {
    let queryParams = `district=${district}&housing_type=${housingType}`;
    const response = await axios.get(
      `${BASE_URL}/api/lowest_price?${queryParams}`
    );
    return response.data.lowest_price;
  } catch (error) {
    console.error("Error fetching house with lowest price:", error);
    throw error;
  }
};

const predictPrice = async (predictionData: PredictionData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/predict`,
      predictionData
    );
    return response.data.predicted_price;
  } catch (error) {
    throw new Error("Failed to get predicted price");
  }
};

export type { GetPostsParams };
export {
  getPosts,
  getPostById,
  predictPrice,
  getHouseWithHighestPrice,
  getHouseWithLowestPrice,
};
