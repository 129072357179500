import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Heading,
  Image,
  Tooltip,
  Skeleton,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BsHouseGear, BsHouseExclamation, BsPinMap } from "react-icons/bs";
import {
  convertDistrictName,
  formatVietnamDong,
  vietnameseToSpecialCondition,
} from "../utils/helper";
import {
  getPostById,
  predictPrice,
  getHouseWithHighestPrice,
  getHouseWithLowestPrice,
} from "../services/HouseService";
import {
  TbAirConditioning,
  TbBuildingHospital,
  TbSchool,
  TbShoppingCart,
} from "react-icons/tb";
import PredictionData from "../interfaces/PredictionForm";

interface HouseData {
  id: string;
  post_title: string;
  thumbnail: string;
  room_area: string;
  district: string;
  ward: string;
  housing_type: string;
  price: string;
  nearby_market: string;
  nearby_school: string;
  nearby_hospital: string;
  have_condition: string;
  posted_time: string;
}

function HousePopup({ houseId }: { houseId: string }) {
  const [post, setPost] = useState<HouseData | null>(null);
  const [loading, setLoading] = useState(true);
  const [predictedPrice, setPredictedPrice] = useState<number | null>(null);
  const [highestPrice, setHighestPrice] = useState<number | null>(null);
  const [lowestPrice, setLowestPrice] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [offsetPercent, setOffsetPercent] = useState<number>(0);
  const [predictionParams, setPredictionParams] =
    useState<PredictionData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        const postData = await getPostById(houseId);
        setPost(postData);
        const params: PredictionData = {
          room_area: parseFloat(postData.room_area),
          nearby_market: parseInt(postData.nearby_market),
          nearby_school: parseInt(postData.nearby_school),
          nearby_hospital: parseInt(postData.nearby_hospital),
          have_condition: parseInt(postData.have_condition),
          district: vietnameseToSpecialCondition(
            postData.district
          ).toLowerCase(),
          housing_type: postData.housing_type,
        };
        setPredictionParams(params);
      } catch (error: any) {
        console.error("Error fetching post detail:", error);
        setError(error.message); // Set error message
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (houseId) {
      fetchData(); // Fetch data only when houseId changes
    }
  }, [houseId]); // Include houseId in the dependencies array

  useEffect(() => {
    const fetchPredictedPrice = async () => {
      try {
        if (predictionParams && post) {
          const price = await predictPrice(predictionParams);
          setPredictedPrice(price);
          setError(null); // Clear error
          setOffsetPercent(
            Number(
              (
                Math.abs((price - parseFloat(post?.price)) / price) * 100
              ).toFixed(2)
            )
          );
        } else {
          setError("Prediction parameters are missing");
        }
      } catch (err: any) {
        console.error("Error:", err);
        setError(err.message);
      }
    };
    const fetchHighestPrice = async () => {
      try {
        if (post) {
          const price = await getHouseWithHighestPrice(
            post.district,
            post.housing_type
          );
          setHighestPrice(price);
        }
      } catch (err: any) {}
    };

    const fetchLowestPrice = async () => {
      try {
        if (post) {
          const price = await getHouseWithLowestPrice(
            post.district,
            post.housing_type
          );
          setLowestPrice(price);
        }
      } catch (err: any) {}
    };
    if (predictionParams) {
      fetchPredictedPrice(); // Fetch predicted price only when predictionParams changes
    }
    if (post) {
      fetchHighestPrice();
      fetchLowestPrice();
    }
  }, [predictionParams, highestPrice]); // Include predictionParams in the dependencies array

  if (loading) {
    return (
      <Box textAlign={"left"}>
        <Skeleton height="20px"></Skeleton>
        <Skeleton height="20px" mt={3}></Skeleton>
        <Flex alignItems={"center"} mt={3} justifyContent={"space-between"}>
          <Skeleton height="20px" width={"60%"}></Skeleton>
          <Skeleton height="20px" width={"20%"}></Skeleton>
        </Flex>
        <Flex mt={"20px"} mb={"20px"} alignItems={"center"}>
          <Skeleton height="280px" width={"50%"} mt={3} mr={3}></Skeleton>
          <Box width={"50%"}>
            <Box>
              <Skeleton height="30px" mt={3}></Skeleton>
              <Skeleton height="30px" width={"80%"} mt={3}></Skeleton>
            </Box>
            <Box pt={3}>
              <Skeleton height="30px" mt={3}></Skeleton>
              <Skeleton height="30px" width={"80%"} mt={3}></Skeleton>
            </Box>
            <Box pt={3}>
              <Skeleton height="30px" mt={3}></Skeleton>
              <Skeleton height="30px" width={"80%"} mt={3}></Skeleton>
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <Box pb={"24px"}>
      <Box mb={"18px"}>
        <Heading as={"h3"} fontSize={"18px"} fontWeight={"500"}>
          {post && post.post_title}
        </Heading>
        <Box mt={"8px"}>
          <Box display={"flex"} alignItems={"center"} mb={"10px"}>
            <Heading as={"h5"} size={"md"}>
              {`${post && formatVietnamDong(post.price)}đ`}
            </Heading>
            <Box marginLeft={"20px"}>
              <Box display={"flex"} gap={"20px"}>
                <Text display={"flex"}>
                  <BsHouseGear size={"20px"} />
                  <Text marginLeft={"8px"}>
                    {post && post.room_area}m<Text as="sup">2</Text>
                  </Text>
                </Text>
                <Text display={"flex"}>
                  <BsHouseExclamation size={"20px"} />{" "}
                  <Text marginLeft={"8px"}>
                    {" "}
                    {post.housing_type === "apartment"
                      ? "Apartment"
                      : post.housing_type === "ordinary_housing"
                      ? " Ordinary Housing"
                      : "Boarding House"}
                  </Text>
                </Text>
                <Text display={"flex"}>
                  <BsPinMap size={"20px"} />
                  <Text marginLeft={"8px"}>
                    {post.ward &&
                      "Ward " + convertDistrictName(post.ward) + " - "}
                    District {convertDistrictName(post.district)}
                  </Text>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} mt={"20px"} alignItems={"center"}>
        <Box width={"50%"} objectFit={"cover"} marginRight={"20px"}>
          {parseInt(post.nearby_school) === 1 ||
          parseInt(post.nearby_market) === 1 ||
          parseInt(post.nearby_hospital) === 1 ||
          parseInt(post.have_condition) === 1 ? (
            <Box
              boxSizing="border-box"
              left={"0"}
              bottom={"0"}
              display={"inline-flex"}
              transform={"translateY(-100%) translateX(calc(100% - 72px))"}
              border={"1px solid #E2E8F0"}
              gap={"10px"}
              position={"absolute"}
              background={"white"}
              borderRadius={"50px"}
              padding={"8px 20px"}
            >
              <Box display={"flex"} boxSizing="border-box">
                <Tooltip label="Nearby schools">
                  <Text
                    display={"inline-block"}
                    mr={parseInt(post.nearby_market) === 1 ? "20px" : "0"}
                    cursor={"pointer"}
                  >
                    {parseInt(post.nearby_school) === 1 ? (
                      <TbSchool size={"24px"} />
                    ) : (
                      <></>
                    )}
                  </Text>
                </Tooltip>
                <Tooltip label="Nearby markets">
                  <Text
                    display={"inline-block"}
                    mr={parseInt(post.nearby_hospital) === 1 ? "20px" : "0"}
                    cursor={"pointer"}
                  >
                    {parseInt(post.nearby_market) === 1 ? (
                      <TbShoppingCart size={"24px"} />
                    ) : (
                      <></>
                    )}
                  </Text>
                </Tooltip>
                <Tooltip label="Nearby hospitals">
                  <Text
                    display={"inline-block"}
                    mr={parseInt(post.have_condition) === 1 ? "20px" : "0"}
                    cursor={"pointer"}
                  >
                    {parseInt(post.nearby_hospital) === 1 ? (
                      <TbBuildingHospital size={"24px"} />
                    ) : (
                      ""
                    )}
                  </Text>
                </Tooltip>
                <Tooltip label="Have Air Conditions">
                  <Text display={"inline-block"} cursor={"pointer"}>
                    {parseInt(post.have_condition) === 1 ? (
                      <TbAirConditioning size={"24px"} />
                    ) : (
                      <></>
                    )}
                  </Text>
                </Tooltip>
              </Box>
            </Box>
          ) : (
            ""
          )}

          <Image
            objectFit={"cover"}
            borderRadius={"8px"}
            width={"100%"}
            height={"280px"}
            src={post && post.thumbnail}
          ></Image>
        </Box>
        <Box flex={"1"}>
          <Box
            mb={"10px"}
            p="2"
            borderRadius="md"
            role="group"
            bg={"brand.opacity"}
            borderLeft={"2px solid"}
            borderColor={"brand.500"}
          >
            <Text
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
              fontSize={"18px"}
            >
              Predicted price{" "}
              <Tooltip
                placement="right"
                label="Predicted house prices from ML model. Updated November 2023."
              >
                <Text>
                  <IoMdInformationCircleOutline size={18} color="gray" />
                </Text>
              </Tooltip>
            </Text>
            <Text fontWeight={"bold"} fontSize={"20px"} color={"brand.500"}>
              {predictedPrice ? (
                `${formatVietnamDong(predictedPrice.toString())}đ`
              ) : (
                <Box>
                  <Spinner size="xs" />
                  Loading...
                </Box>
              )}
            </Text>
            <Text display={"inline-flex"} fontSize={"16px"}>
              The price is
              <Text
                ml={1}
                mr={1}
                fontWeight={"bold"}
                color={
                  predictedPrice && predictedPrice < parseFloat(post.price)
                    ? "danger.500"
                    : "warning.700"
                }
              >
                {predictedPrice &&
                post &&
                predictedPrice < parseFloat(post.price)
                  ? `${offsetPercent}% higher`
                  : `${offsetPercent}% lower`}
              </Text>
              than predicted
            </Text>
          </Box>
          <Box
            mb={"10px"}
            marginTop={3}
            p="2"
            borderRadius="md"
            role="group"
            bg={"danger.100"}
            borderLeft={"2px solid"}
            borderColor={"danger.500"}
          >
            <Text
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
              fontSize={"18px"}
            >
              Highest Price
              <Tooltip
                label={`Highest price for similar location (${
                  post.district
                }) and housing type (${
                  post.housing_type === "apartment"
                    ? "Apartment"
                    : post.housing_type === "ordinary_housing"
                    ? "Ordinary Housing"
                    : "Boarding House"
                }).`}
                placement="right"
              >
                <Text>
                  <IoMdInformationCircleOutline size={18} color="gray" />
                </Text>
              </Tooltip>
            </Text>

            <Text fontWeight={"bold"}>
              {highestPrice
                ? `${formatVietnamDong(highestPrice.toString())}đ`
                : "No data match!"}
            </Text>
          </Box>
          <Box
            mb={"10px"}
            marginTop={3}
            p="2"
            borderRadius="sm"
            role="group"
            bg={"warning.100"}
            borderLeft={"2px solid"}
            borderColor={"warning.500"}
          >
            <Text
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
              fontSize={"18px"}
            >
              Lowest Price{" "}
              <Tooltip
                label={`Lowest price for similar location (${
                  post.district
                }) and housing type (${
                  post.housing_type === "apartment"
                    ? "Apartment"
                    : post.housing_type === "ordinary_housing"
                    ? "Ordinary Housing"
                    : "Boarding House"
                }).`}
                placement="right"
              >
                <Text>
                  <IoMdInformationCircleOutline size={18} color="gray" />
                </Text>
              </Tooltip>
            </Text>

            <Text fontWeight={"bold"}>
              {" "}
              {lowestPrice
                ? `${formatVietnamDong(lowestPrice.toString())}đ`
                : "No data match!"}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default HousePopup;
