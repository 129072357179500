import { Box, Container, Text, Stack, Image, position } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaHouseChimneyUser, FaHouseChimneyMedical } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { setRole } from "../redux/slices/authSlice";
import BackgroundHomepage from "../components/icons/BackgroundHomepage";
import HouseIconHomepage from "../components/icons/HouseIconHomepage";

export default function Welcome() {
  const dispatch = useDispatch();
  const handleRoleSelection = (
    role: "cLixKTzzBTAKG6qa7TsrAkjFQLNQRX" | "AMHVv7fR76cyi2qYzNJcFHggtpk4LQ"
  ) => {
    dispatch(setRole(role));
    console.log(dispatch(setRole(role)));
  };
  return (
    <Box>
      <Container width={"100%"}>
        <Box
          width={"100%"}
          height={"100vh"}
          left={0}
          position={"absolute"}
          z-index={"-1000"}
        >
          <BackgroundHomepage />
        </Box>
        <Stack
          as={Box}
          textAlign={"center"}
          pt={{ base: 10, md: 28 }}
          position={"relative"}
          zIndex={"1000"}
        >
          <Box display={"flex"} justifyContent={"center"}>
            <HouseIconHomepage height="150px" />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            fontSize={{ base: "2xl", sm: "3xl", md: "5xl" }}
            fontWeight={"600"}
          >
            <Text color={"#3F3D56"}>FIS</Text>
            <Text color={"brand.500"}>Apartment</Text>
          </Box>
          <Text color={"gray.500"}>
            Explore innovative avenues for house rentals. Seamlessly discover
            your ideal home and effortlessly post rental advertisements with
            ease
            <br />
          </Text>
        </Stack>
        <Stack
          direction={"column"}
          spacing={3}
          align={"center"}
          alignSelf={"center"}
          position={"relative"}
        >
          <Text color={"gray.500"} mt={2}>
            Please tell us who you are?
          </Text>
          <Box display={"flex"} gap={4}>
            {" "}
            <Link
              to="/detail"
              onClick={() =>
                handleRoleSelection("cLixKTzzBTAKG6qa7TsrAkjFQLNQRX")
              }
            >
              <Box
                color={"gray.500"}
                borderWidth={"2px"}
                borderRadius={"md"}
                p={6}
                _hover={{
                  borderColor: "brand.500",
                  color: "brand.500",
                }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <FaHouseChimneyUser size={"30px"} />
                <Text mt={1}> I'm a renter</Text>
              </Box>
            </Link>
            <Link
              to="/detail"
              onClick={() =>
                handleRoleSelection("AMHVv7fR76cyi2qYzNJcFHggtpk4LQ")
              }
            >
              <Box
                borderWidth={"2px"}
                color={"gray.500"}
                borderRadius={"md"}
                p={6}
                _hover={{
                  borderColor: "brand.500",
                  color: "brand.500",
                }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <FaHouseChimneyMedical size={"30px"} />
                <Text mt={1}> I'm a Landlord</Text>
              </Box>
            </Link>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
