import React from "react";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import "./App.css";

const theme = extendTheme({
  colors: {
    brand: {
      500: "#47BB78",
      100: "#afe1c4",
      200: "#69c790",
      300: "#40a76b",
      600: "#338555",
      700: "#276440",
      800: "#1a452c",
      opacity: "rgb(200 248 215 / 41%)",
    },
    danger: {
      100: "rgba(244, 125, 116,0.2)",
      500: "rgb(244, 125, 116)",
    },
    warning: {
      100: "rgba(246, 188, 103,0.2)",
      500: "rgb(246, 188, 103)",
      700: "rgb(250, 170, 52)",
    },
    gray: {
      300: "#D4D8DF",
      500: "#718096",
    },
    black: {
      500: "#3F3D56",
    },
  },
});

const queryClient = new QueryClient();

const App: React.FunctionComponent = () => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
