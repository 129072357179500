// /src/components/features/Detail/DetailPage.tsx
import React, { useEffect, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab, AuthState } from "../redux/slices/authSlice";
import { Box } from "@chakra-ui/react";
import Dashboard from "../components/Dashboard";
import HousePricePrediction from "./PostRentalListing";
import Explore from "../components/Explore";

function DetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedTab } = useSelector(
    (state: { auth: AuthState }) => state.auth
  );
  const [selectedComponent, setSelectedComponent] = useState<ReactNode>(null);
  // Handle tab change
  const role = localStorage.getItem("role");
  useEffect(() => {
    switch (selectedTab) {
      case "dashboard":
        setSelectedComponent(<Dashboard />);
        break;
      case "predict":
        setSelectedComponent(<HousePricePrediction />);
        break;
      case "explore":
        setSelectedComponent(<Explore />);
        break;
      default:
        setSelectedComponent(<Explore />);
        break;
    }
  }, [selectedTab]);

  // Check role and navigate if necessary
  useEffect(() => {
    if (!role) {
      navigate("/");
    } else {
      if (!window.location.hash) {
        navigate("/detail#explore");
      }
    }
  }, [role, navigate]);
  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    if (hash && hash !== selectedTab) {
      dispatch(setSelectedTab(hash));
    }
  }, [dispatch, selectedTab]);

  return <Box w={"100%"}>{selectedComponent}</Box>;
}

export default DetailPage;
