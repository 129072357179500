function HouseIconHomepage({ height }: { height: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="auto"
      height={height}
      data-name="Layer 1"
      viewBox="0 0 572.88 361.347"
    >
      <path
        fill="#47bb78"
        d="M418.012 88c0 34.668-54.19 136.907-85.26 192.678a24.312 24.312 0 01-42.48 0c-31.07-55.771-85.26-158.01-85.26-192.678 0-48.601 47.682-88 106.5-88s106.5 39.399 106.5 88z"
      ></path>
      <circle cx="312.012" cy="87" r="58" fill="#fff"></circle>
      <circle cx="69.982" cy="104.74" r="34.74" fill="#ff6584"></circle>
      <ellipse
        cx="311.512"
        cy="354.856"
        fill="#47bb78"
        rx="84.092"
        ry="6.492"
      ></ellipse>
      <circle
        cx="356.74"
        cy="401.758"
        r="19.986"
        fill="#47bb78"
        opacity="0.4"
        transform="rotate(-80.783 41.683 451.368)"
        style={{ isolation: "isolate" }}
      ></circle>
      <path
        fill="#3f3d56"
        d="M510.77 235.53c-157.39-59-349.35-1.48-460.18 116.18-.63.66-1.26 1.33-1.87 2h-1.69c.58-.67 1.18-1.34 1.77-2C161.93 225.1 351.34 174.64 510.77 235.53zM553.78 255.1a375.733 375.733 0 00-43.01-19.57 341.325 341.325 0 0143.01 19.57z"
      ></path>
      <circle
        cx="467.641"
        cy="174.315"
        r="21.92"
        fill="#47bb78"
        opacity="0.4"
        style={{ isolation: "isolate" }}
      ></circle>
      <path
        fill="#3f3d56"
        d="M470.081 342.921c1.694 6.262 7.497 10.14 7.497 10.14s3.055-6.274 1.361-12.536-7.496-10.14-7.496-10.14-3.055 6.274-1.362 12.536z"
      ></path>
      <path
        fill="#47bb78"
        d="M472.57 341.575c4.649 4.525 5.217 11.48 5.217 11.48s-6.969-.38-11.617-4.904-5.216-11.481-5.216-11.481 6.968.38 11.617 4.905z"
      ></path>
      <path
        fill="#3f3d56"
        d="M3.36 255.855v9.66l-.48.52v-10.28c.16.03.32.07.48.1z"
      ></path>
      <path
        fill="#ccc"
        d="M34.14 351.705c-.14.23-.29.46-.44.69H2.88v-96.06l.48-.48 4.09 4.65v-6.8h13v21.58l13.13 14.91.12.14c.16.5.31 1 .46 1.5a.035.035 0 00.01.02 177.405 177.405 0 014.84 19.19c2.86 15.29 2.43 29.31-4.87 40.66z"
      ></path>
      <path
        fill="#3f3d56"
        d="M5.427 308.953H13.700999999999999V315.84799999999996H5.427z"
      ></path>
      <path
        fill="#3f3d56"
        d="M5.427 321.365H13.700999999999999V328.26H5.427z"
      ></path>
      <path
        fill="#3f3d56"
        d="M268.588 295.852l-4.137-6.895 38.614-33.098c28.547-13.741 62.958-9.776 99.294 0v9.653l-35.856 38.615-41.373 8.274z"
      ></path>
      <path
        fill="#ccc"
        d="M419.45 275.282c6.858-7.628 7.81-14.88 0-21.577h-13v6.804l-4.091-4.65-33.098 33.098-5.517 5.516-6.895 27.582 12.412 30.339h63.437v-62.058z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M264.45 288.956h104.81v63.438H264.45c-12.68-13.719-6.092-38.723 0-63.438z"
      ></path>
      <path fill="#3f3d56" d="M332.715 308.953H354.78V328.26H332.715z"></path>
      <path
        fill="#ccc"
        d="M304.444 308.263H323.75100000000003V352.393H304.444z"
      ></path>
      <path
        fill="#3f3d56"
        d="M282.379 315.159L282.379 308.264 274.105 308.264 274.105 315.159 281.689 315.159 282.379 315.159z"
      ></path>
      <path
        fill="#3f3d56"
        d="M281.689 320.675L274.105 320.675 274.105 327.571 282.379 327.571 282.379 320.675 281.689 320.675z"
      ></path>
      <path
        fill="#3f3d56"
        d="M287.895 308.263H296.169V315.15799999999996H287.895z"
      ></path>
      <path fill="#3f3d56" d="M287.895 320.675H296.169V327.57H287.895z"></path>
      <path
        fill="#3f3d56"
        d="M398.911 315.849L398.911 308.953 390.636 308.953 390.636 315.849 398.221 315.849 398.911 315.849z"
      ></path>
      <path
        fill="#3f3d56"
        d="M398.221 321.365L390.636 321.365 390.636 328.26 398.911 328.26 398.911 321.365 398.221 321.365z"
      ></path>
      <path
        fill="#3f3d56"
        d="M404.427 308.953H412.701V315.84799999999996H404.427z"
      ></path>
      <path fill="#3f3d56" d="M404.427 321.365H412.701V328.26H404.427z"></path>
      <path
        fill="#3f3d56"
        d="M81.966 180.01L75.071 174.493 113.685 140.016 211.599 140.016 211.599 170.356 148.162 213.108 81.966 180.01z"
      ></path>
      <path
        fill="#ccc"
        d="M229.45 159.356v-18.651h-13v4.568l-4.851-5.257-31.72 34.477-12.41 8.275-19.245 140.603 29.023 29.023h67.45c10.335-51.988 18.53-105.395 0-176.522z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M75.07 174.493h104.81v177.9H75.07c-11.469-69.112-17.07-133.205 0-177.9z"
      ></path>
      <path fill="#ccc" d="M115.753 308.953H135.06V352.394H115.753z"></path>
      <path
        fill="#3f3d56"
        d="M95.067 321.365L95.067 314.47 86.793 314.47 86.793 321.365 94.378 321.365 95.067 321.365z"
      ></path>
      <path
        fill="#3f3d56"
        d="M94.378 326.881L86.793 326.881 86.793 333.777 95.067 333.777 95.067 326.881 94.378 326.881z"
      ></path>
      <path fill="#3f3d56" d="M100.583 314.469H108.857V321.364H100.583z"></path>
      <path
        fill="#3f3d56"
        d="M150.92 326.881L143.335 326.881 143.335 333.777 151.609 333.777 151.609 326.881 150.92 326.881z"
      ></path>
      <path fill="#3f3d56" d="M157.125 314.469H165.399V321.364H157.125z"></path>
      <path
        fill="#3f3d56"
        d="M157.125 326.881H165.399V333.77599999999995H157.125z"
      ></path>
      <path
        fill="#3f3d56"
        d="M209.53 183.457L209.53 176.562 201.256 176.562 201.256 183.457 208.841 183.457 209.53 183.457z"
      ></path>
      <path
        fill="#3f3d56"
        d="M208.841 188.974L201.256 188.974 201.256 195.869 209.53 195.869 209.53 188.974 208.841 188.974z"
      ></path>
      <path
        fill="#3f3d56"
        d="M215.047 176.562H223.321V183.45700000000002H215.047z"
      ></path>
      <path
        fill="#3f3d56"
        d="M215.047 188.973H223.321V195.86800000000002H215.047z"
      ></path>
      <path
        fill="#3f3d56"
        d="M209.53 222.072L209.53 215.176 201.256 215.176 201.256 222.072 208.841 222.072 209.53 222.072z"
      ></path>
      <path
        fill="#3f3d56"
        d="M208.841 227.588L201.256 227.588 201.256 234.483 209.53 234.483 209.53 227.588 208.841 227.588z"
      ></path>
      <path fill="#3f3d56" d="M215.047 215.176H223.321V222.071H215.047z"></path>
      <path fill="#3f3d56" d="M215.047 227.587H223.321V234.482H215.047z"></path>
      <path
        fill="#3f3d56"
        d="M209.53 260.686L209.53 253.79 201.256 253.79 201.256 260.686 208.841 260.686 209.53 260.686z"
      ></path>
      <path
        fill="#3f3d56"
        d="M208.841 266.202L201.256 266.202 201.256 273.097 209.53 273.097 209.53 266.202 208.841 266.202z"
      ></path>
      <path fill="#3f3d56" d="M215.047 253.79H223.321V260.685H215.047z"></path>
      <path fill="#3f3d56" d="M215.047 266.202H223.321V273.097H215.047z"></path>
      <path
        fill="#3f3d56"
        d="M209.53 299.3L209.53 292.404 201.256 292.404 201.256 299.3 208.841 299.3 209.53 299.3z"
      ></path>
      <path
        fill="#3f3d56"
        d="M208.841 304.816L201.256 304.816 201.256 311.711 209.53 311.711 209.53 304.816 208.841 304.816z"
      ></path>
      <path fill="#3f3d56" d="M215.047 292.404H223.321V299.299H215.047z"></path>
      <path
        fill="#3f3d56"
        d="M215.047 304.816H223.321V311.71099999999996H215.047z"
      ></path>
      <path
        fill="#3f3d56"
        d="M86.793 183.457h79.986v16.549c-25.638-7.076-52.374-6.568-79.986 0zM86.793 216.555q37.447 11.033 79.986 0v16.549H86.793zM86.793 249.653h79.986v16.549c-27.26-8.705-53.95-9.115-79.986 0zM86.793 282.75c26.086 10.054 52.772 9.636 79.986 0v16.55H86.793zM516.68 254h56.2v27.08l-5.02 1.57-16.7 5.212-26.2-6.069-26.17-6.064-13.82-3.202-6.9-2.552c5.74-7.877 16.18-14.07 38.61-15.975z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M572.88 269.975v82.42h-94.81c-.03-.05-.05-.097-.08-.148v-.005a1.265 1.265 0 00-.08-.166q-2.22-4.469-3.98-8.914c-9.95-25.079-8.87-49.494 4.14-73.187z"
      ></path>
      <path
        fill="#ccc"
        d="M518.753 308.953H538.0600000000001V352.394H518.753z"
      ></path>
      <path
        fill="#3f3d56"
        d="M498.067 321.365L498.067 314.47 489.793 314.47 489.793 321.365 497.378 321.365 498.067 321.365z"
      ></path>
      <path
        fill="#3f3d56"
        d="M497.378 326.881L489.793 326.881 489.793 333.777 498.067 333.777 498.067 326.881 497.378 326.881z"
      ></path>
      <path fill="#3f3d56" d="M503.583 314.469H511.857V321.364H503.583z"></path>
      <path
        fill="#3f3d56"
        d="M503.583 326.881H511.857V333.77599999999995H503.583z"
      ></path>
      <path
        fill="#3f3d56"
        d="M554.609 321.365L554.609 314.47 546.335 314.47 546.335 321.365 553.92 321.365 554.609 321.365z"
      ></path>
      <path
        fill="#3f3d56"
        d="M553.92 326.881L546.335 326.881 546.335 333.777 554.609 333.777 554.609 326.881 553.92 326.881z"
      ></path>
      <path fill="#3f3d56" d="M560.125 314.469H568.399V321.364H560.125z"></path>
      <path
        fill="#3f3d56"
        d="M560.125 326.881H568.399V333.77599999999995H560.125z"
      ></path>
      <path
        fill="#3f3d56"
        d="M498.793 222h11.885a44.202 44.202 0 0144.202 44.202h-56.087zM138.793 102h11.885a44.202 44.202 0 0144.202 44.202h-56.087zM328.793 213h11.885a44.202 44.202 0 0144.202 44.202h-56.087z"
      ></path>
      <path
        fill="#3f3d56"
        d="M498.793 282.751H554.88V299.29999999999995H498.793z"
      ></path>
      <path fill="#3f3d56" d="M2.88 351.71H572.88V353.71H2.88z"></path>
      <path fill="#3f3d56" d="M53.451 272.705H55.451V351.705H53.451z"></path>
      <path
        fill="#47bb78"
        d="M73.909 282.773c.127 27.624-19.308 50.108-19.308 50.108S29.71 310.1 34.83 282.953c5.15-27.3 21.2-49.992 19.309-50.108 0 0 19.641 22.304 19.769 49.928z"
      ></path>
      <path fill="#3f3d56" d="M253.451 272.705H255.451V351.705H253.451z"></path>
      <path
        fill="#47bb78"
        d="M273.909 282.773c1.802 27.566-19.308 50.108-19.308 50.108s-19.642-22.304-19.77-49.928 19.309-50.108 19.309-50.108c-1.895 7.572 18.31 27.624 19.769 49.928z"
      ></path>
      <path
        fill="#3f3d56"
        d="M125.513 144.289L212.387 140.039 248.83 170.458 242.387 176.275 184.678 174.976 125.513 144.289z"
      ></path>
      <path
        fill="#3f3d56"
        d="M38.83 285.455l-4.68 4.23-.57.51-1.19 1.08-29.51-.66v-34.86q-1.44-.315-2.88-.6l2.39-.12.49.41 4.57 3.81 13 10.86 14.52 12.12z"
      ></path>
      <path
        fill="#3f3d56"
        d="M315.513 259.289L402.387 255.039 438.83 285.458 432.387 291.275 374.678 289.976 315.513 259.289z"
      ></path>
    </svg>
  );
}

export default HouseIconHomepage;
