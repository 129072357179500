import { Chart, ArcElement } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import StatisticBlock from "./StatisticBlock";
import PieChart from "./charts/SamplePieChart"; // Adjust the import
import { VerticalBarChart } from "./charts/SampleVerticalBarChart";
import { Flex, Heading, Container, Box } from "@chakra-ui/react";
function Dashboard(): JSX.Element {
  Chart.register(ArcElement);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const chartData = {
    labels: ["Label 1", "Label 2", "Label 3"],
    datasets: [
      {
        labels: ["January", "February", "March"],
        data: [100, 0, 0],
        backgroundColor: ["#FF8080", "#FF8080", "#FF8080"],
      },
    ],
  };

  const chartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    labels: {
      display: true,
    },
  };

  return (
    <Box px={{ base: 2, md: 4 }} py={"5"}>
      <StatisticBlock />
      <Flex mt={10} wrap={"wrap"} justifyContent={"space-between"}>
        <Flex alignItems="left" direction="column">
          <Heading size="lg" fontSize={"2xl"} fontWeight={"600"} color="black">
            Pie chart
          </Heading>
          <PieChart chartData={chartData} options={chartOptions} />{" "}
        </Flex>
        <Flex alignItems="left" direction="column">
          <Heading size="lg" fontSize={"2xl"} fontWeight={"600"} color="black">
            Pie chart
          </Heading>
          <PieChart chartData={chartData} options={chartOptions} />{" "}
        </Flex>
        <Flex alignItems="left" direction="column">
          <Heading size="lg" fontSize={"2xl"} fontWeight={"600"} color="black">
            Pie chart
          </Heading>
          <PieChart chartData={chartData} options={chartOptions} />
        </Flex>
      </Flex>
      <Container
        mt={10}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <Flex direction={"column"} width={"500px"}>
          <Heading size="lg" fontSize={"2xl"} fontWeight={"600"} color="black">
            Vertical Bar Chart
          </Heading>
          <VerticalBarChart />
        </Flex>
        <Flex direction={"column"} width={"500px"}>
          <Heading size="lg" fontSize={"2xl"} fontWeight={"600"} color="black">
            Vertical Bar Chart
          </Heading>
          <VerticalBarChart />
        </Flex>
      </Container>
    </Box>
  );
}

export default Dashboard;
