const removeAccents = (str: string): string => {
  if (str === undefined) {
    return "";
  }
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

function removeVietnameseTones(str: string) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  return str;
}
function formatVietnamDong(money: string) {
  if (isNaN(parseFloat(money)) || money.length <= 5) {
    return money;
  } else {
    return money
      .split("")
      .reverse()
      .reduce((prev: string, next: string, index: number) => {
        return (index % 3 ? next : next + ",") + prev;
      });
  }
}

function vietnameseToSpecialCondition(text: string): string {
  // Remove accents
  text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Replace spaces with underscores and remove non-alphanumeric characters
  text = text.replace(/\s+/g, "_").replace(/\W/g, "");

  return text;
}

const convertProvinceName = (provinceName: string): string => {
  let normalizedProvinceName = removeAccents(provinceName);
  if (
    normalizedProvinceName.includes("thanh pho") ||
    normalizedProvinceName.includes("thanh phố")
  ) {
    normalizedProvinceName = normalizedProvinceName
      .replace("thanh pho", "")
      .replace("thanh phố", "")
      .trim();
  } else if (
    normalizedProvinceName.includes("tinh") ||
    normalizedProvinceName.includes("tỉnh")
  ) {
    normalizedProvinceName = normalizedProvinceName
      .replace("tinh", "")
      .replace("tỉnh", "")
      .trim();
  }

  // Normalize the name
  normalizedProvinceName = removeAccents(normalizedProvinceName);

  // Replace "Đ" with "D"
  normalizedProvinceName = normalizedProvinceName.replace(/đ/g, "d");
  return normalizedProvinceName;
};

const convertDistrictName = (districtName: string): string => {
  let normalizeDistrictName = removeAccents(districtName);
  if (
    normalizeDistrictName.includes("quan") ||
    normalizeDistrictName.includes("quận")
  ) {
    normalizeDistrictName = normalizeDistrictName
      .replace("quan", "")
      .replace("quận", "")
      .trim();
  } else if (
    normalizeDistrictName.includes("huyen") ||
    normalizeDistrictName.includes("huyện")
  ) {
    normalizeDistrictName = normalizeDistrictName
      .replace("huyen", "")
      .replace("huyen", "")
      .trim();
  }

  // Normalize the name
  normalizeDistrictName = removeAccents(normalizeDistrictName);

  // Replace "Đ" with "D"
  normalizeDistrictName = normalizeDistrictName.replace(/đ/g, "d");
  return capitalizeFirstLetter(normalizeDistrictName);
};

const capitalizeFirstLetter = (str: string): string => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export {
  removeVietnameseTones,
  formatVietnamDong,
  convertProvinceName,
  removeAccents,
  capitalizeFirstLetter,
  convertDistrictName,
  vietnameseToSpecialCondition,
};
