import React, { useState } from "react";
import { Pie } from "react-chartjs-2";

interface PieChartProps {
  chartData: {
    // Change 'data' to 'chartData' to match the prop name
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
  options?: any;
}

const PieChart: React.FC<PieChartProps> = ({ chartData, options }) => {
  return (
    <div className="chart-container">
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default PieChart;
