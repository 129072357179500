import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux"; // Import useSelector
import { useQuery, useInfiniteQuery, useQueryClient } from "react-query";
import {
  Skeleton,
  Card,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Tooltip,
  CardBody,
  Box,
  Flex,
  Grid,
  Heading,
  FormControl,
  Checkbox,
  Select,
  Text,
  Input,
  GridItem,
  Image,
  Button,
  Menu,
  MenuList,
  SimpleGrid,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  TbSchool,
  TbShoppingCart,
  TbBuildingHospital,
  TbAirConditioning,
} from "react-icons/tb";
import { PiCaretDownBold } from "react-icons/pi";
import { PiChartLineDuotone } from "react-icons/pi";
import { BsHouseGear, BsHouseExclamation, BsPinMap } from "react-icons/bs";
import { IoSparkles } from "react-icons/io5";
import HousePopup from "./HousePopup";
import { AuthState } from "../redux/slices/authSlice";
import {
  removeVietnameseTones,
  formatVietnamDong,
  convertDistrictName,
  capitalizeFirstLetter,
} from "../utils/helper";
import { getDistrictsByProvinceId } from "../services/ProvinceService";
import District from "../interfaces/District";
import { getPosts } from "../services/HouseService";
interface HouseData {
  id: string;
  post_title: string;
  thumbnail: string;
  room_area: string;
  district: string;
  ward: string;
  housing_type: string;
  price: string;
  nearby_market: string;
  nearby_school: string;
  nearby_hospital: string;
  have_condition: string;
  posted_time: string;
}

function PageNotFound({ selectedCity }: { selectedCity?: string }) {
  return (
    <Box textAlign="center" minH="100vh">
      <Text color="brand.500" fontSize="80px" fontWeight="bold">
        404
      </Text>
      <Box display="flex" justifyContent="center" mb="20px">
        <Image src="/404image.svg" width="280px" />
      </Box>
      <Box marginBottom="20px" textAlign="center">
        <Flex fontSize="20px" justifyContent="center">
          <Text>No data found</Text>
          <Flex marginLeft="5px">
            {!selectedCity ? (
              "!"
            ) : (
              <span>
                {"for "}
                <span style={{ fontWeight: "bold" }}>
                  {convertDistrictName(selectedCity)}
                </span>
              </span>
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

function Explore(): JSX.Element {
  const provinceId = useSelector(
    (state: { auth: AuthState }) => state.auth.provinceId
  );
  const selectedCity = useSelector(
    (state: { auth: AuthState }) => state.auth.selectedCity
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const elementRef = useRef(null);
  const [fetchTrigger, setFetchTrigger] = useState(true);
  // State for districts and house filters
  const [district, setDistrict] = useState("");
  const [houseType, setHouseType] = useState("");
  const [nearbyMarkets, setNearbyMarkets] = useState(false);
  const [nearbySchools, setNearbySchools] = useState(false);
  const [nearbyHospitals, setNearbyHospitals] = useState(false);
  const [hasAirConditioning, setHasAirConditioning] = useState(false);
  const [desiredRoomArea, setDesiredRoomArea] = useState("");
  const [houseId, setHouseId] = useState("");
  const observer = useRef<IntersectionObserver | null>(null);
  // Fetch districts with react-query
  const { data: districts, isLoading: districtsLoading } = useQuery(
    ["districts", provinceId],
    () => getDistrictsByProvinceId(provinceId),
    { enabled: !!provinceId }
  );

  // Fetch posts with react-query
  const {
    data: posts,
    fetchNextPage,
    hasNextPage,
    isFetching: postsFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["posts"],
    ({ pageParam = 1 }) =>
      getPosts({
        page: pageParam,
        perPage: 9,
        district,
        houseType,
        nearbyMarkets,
        nearbySchools,
        nearbyHospitals,
        hasAirConditioning,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.current_page < lastPage.total_pages) {
          return allPages.length + 1;
        }
        return undefined;
      },
      enabled: !!provinceId && fetchTrigger,
    }
  );

  useEffect(() => {
    const currentObserver = observer.current;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    });

    if (elementRef.current) {
      observer.current.observe(elementRef.current);
    }

    return () => currentObserver?.disconnect();
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    refetch();
  };

  if (selectedCity !== "ho chi minh") {
    return <PageNotFound selectedCity={selectedCity} />;
  }
  return (
    <Box p={"20px"}>
      <Heading
        fontSize={"2xl"}
        fontWeight={"600"}
        marginRight={5}
        marginBottom={5}
      >
        Exploring the housing options available for rent in
        <Text as={"span"} color={"green.500"}>
          {" "}
          {capitalizeFirstLetter(removeVietnameseTones(selectedCity))}
          {" City"}
        </Text>
      </Heading>
      {/* <form onSubmit={handleSubmit}> */}
      <Box display="flex" mb={4} alignItems={"center"}>
        <FormControl ml={4} maxW={"160px"}>
          <Select
            placeholder="Select District"
            focusBorderColor="brand.500"
            // name="address_district"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
          >
            {districts?.map((district) => (
              <option key={district.district_id} value={district.district_name}>
                {district.district_name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl ml={4} maxW={"200px"}>
          <Select
            placeholder="Select House Type"
            focusBorderColor="brand.500"
            // name="housing_type"
            value={houseType}
            onChange={(e) => setHouseType(e.target.value)}
          >
            <option key={"1"} value={"apartment"}>
              Apartment
            </option>
            <option key={"2"} value={"ordinary_housing"}>
              Ordinary Housing
            </option>
            <option key={"3"} value={"boarding_house"}>
              Boarding House
            </option>
          </Select>
        </FormControl>
        <FormControl ml={4} id="desired_room_area" maxW={"170px"}>
          <Box display={"flex"}>
            <Input
              placeholder="Desired room area"
              focusBorderColor="brand.500"
              value={desiredRoomArea}
              onChange={(e) => setDesiredRoomArea(e.target.value)}
            />
          </Box>
        </FormControl>
        <FormControl ml={4} id="price" maxW={"160px"}>
          <Popover>
            <PopoverTrigger>
              <Button
                bg={"transparent"}
                borderColor={"gray.200"}
                borderWidth={"1px"}
                rightIcon={<PiCaretDownBold />}
                fontWeight={"normal"}
              >
                Utilities Filter
              </Button>
            </PopoverTrigger>
            <PopoverContent width={"fit-content"}>
              <PopoverArrow />
              <PopoverBody>
                <Box
                  padding={"4px"}
                  display={"inline-flex"}
                  flexDirection={"column"}
                  flexWrap={"wrap"}
                  gap={"10px"}
                >
                  <Checkbox
                    isChecked={nearbyMarkets}
                    onChange={(e) => setNearbyMarkets(e.target.checked)}
                    colorScheme="brand"
                  >
                    Nearby Markets
                  </Checkbox>
                  <Checkbox
                    isChecked={nearbySchools}
                    onChange={(e) => setNearbySchools(e.target.checked)}
                    colorScheme="brand"
                  >
                    Nearby Schools
                  </Checkbox>
                  <Checkbox
                    isChecked={nearbyHospitals}
                    onChange={(e) => setNearbyHospitals(e.target.checked)}
                    colorScheme="brand"
                  >
                    Nearby Hospitals
                  </Checkbox>

                  <Checkbox
                    isChecked={hasAirConditioning}
                    onChange={(e) => setHasAirConditioning(e.target.checked)}
                    colorScheme="brand"
                  >
                    Has Air Conditioning
                  </Checkbox>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormControl>

        <Box ml={4}>
          <Button
            isLoading={postsFetching}
            onClick={handleSubmit}
            // type="submit"
            leftIcon={<IoSparkles />}
            colorScheme="brand"
            variant="solid"
          >
            Apply Filter
          </Button>
        </Box>
      </Box>
      {/* </form> */}
      <Box
        display={"flex"}
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}
        width={"100%"}
      >
        {posts && posts.pages[0].posts.length === 0 && (
          <Box>
            <PageNotFound />
          </Box>
        )}
      </Box>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        {" "}
        <SimpleGrid spacing={8} minChildWidth="360px" width={"100%"}>
          {posts?.pages.map((page) =>
            page.posts.map((item: any, index: number) => (
              <GridItem key={index} width={"100%"} minH={"435px"}>
                <Card
                  maxW="md"
                  height={"100%"}
                  overflow={"hidden"}
                  boxShadow={"none"}
                  border={"1px solid #e2e8f0"}
                >
                  <Image
                    width={"100%"}
                    height={"200px"}
                    objectFit="cover"
                    src={
                      item
                        ? item.thumbnail
                        : "https://liftlearning.com/wp-content/uploads/2020/09/default-image.png"
                    }
                    alt={item.post_title}
                  />
                  <CardBody
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Text noOfLines={2} fontWeight={"bold"}>
                        {item.post_title}
                      </Text>
                      <Box marginTop={"10px"}>
                        <Text display={"flex"}>
                          <BsHouseGear size={"20px"} />
                          <Text marginLeft={"8px"}>
                            {item.room_area} m<Text as="sup">2</Text>
                          </Text>
                        </Text>
                        <Text display={"flex"}>
                          <BsHouseExclamation size={"20px"} />{" "}
                          <Text marginLeft={"8px"}>
                            {item.housing_type === "apartment"
                              ? "Apartment"
                              : item.housing_type === "ordinary_housing"
                              ? "Ordinary Housing "
                              : "Boarding House"}
                          </Text>
                        </Text>
                        <Text display={"flex"}>
                          <BsPinMap size={"20px"} />
                          <Text marginLeft={"8px"}>
                            {item.ward &&
                              "Ward " + convertDistrictName(item.ward) + " - "}
                            District {convertDistrictName(item.district)}
                          </Text>
                        </Text>
                      </Box>
                    </Box>
                    <Flex gap={"10px"} marginTop={"10px"}>
                      <Tooltip label="Nearby schools">
                        <Text display={"inline-block"}>
                          {parseInt(item.nearby_school) === 1 ? (
                            <TbSchool size={"24px"} />
                          ) : (
                            ""
                          )}
                        </Text>
                      </Tooltip>
                      <Tooltip label="Nearby markets">
                        <Text display={"inline-block"}>
                          {parseInt(item.nearby_market) === 1 ? (
                            <TbShoppingCart size={"24px"} />
                          ) : (
                            ""
                          )}
                        </Text>
                      </Tooltip>
                      <Tooltip label="Nearby hospitals">
                        <Text display={"inline-block"}>
                          {parseInt(item.nearby_hospital) === 1 ? (
                            <TbBuildingHospital size={"24px"} />
                          ) : (
                            ""
                          )}
                        </Text>
                      </Tooltip>
                      <Tooltip label="Have Air Conditions">
                        <Text display={"inline-block"}>
                          {parseInt(item.have_condition) === 1 ? (
                            <TbAirConditioning size={"24px"} />
                          ) : (
                            ""
                          )}
                        </Text>
                      </Tooltip>
                    </Flex>

                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      mt={3}
                    >
                      <Text color="brand.500" fontWeight={"bold"}>
                        {formatVietnamDong(item.price)}
                        {"đ"}
                      </Text>
                      <Menu>
                        <Flex gap={4}>
                          <Tooltip label="Detailed Analysis">
                            <Button
                              onClick={() => {
                                setHouseId(item.id);
                                onOpen();
                              }}
                              as={IconButton}
                              height={"25px"}
                              width={"25px"}
                              minW={"auto"}
                              padding={0}
                              icon={<PiChartLineDuotone />}
                              border={"0.2px solid rgb(71 187 120 / 30%)"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              borderRadius={"50%"}
                              backgroundColor={"rgb(200 248 215 / 41%)"}
                              color="brand.500"
                            />
                          </Tooltip>
                        </Flex>

                        <MenuList>
                          <Box>
                            <Text fontWeight={"bold"}></Text>
                          </Box>
                        </MenuList>
                      </Menu>
                    </Flex>
                  </CardBody>
                </Card>
              </GridItem>
            ))
          )}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={"850px"}>
              <ModalHeader>Detailed Analysis</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <HousePopup houseId={houseId.toString()} />
              </ModalBody>
            </ModalContent>
          </Modal>
          {postsFetching &&
            Array.from({ length: 6 }).map((_, index) => (
              <Box
                key={index}
                padding="5px"
                border="1px solid #e2e8f0"
                minW="360px"
                borderRadius="0.375rem"
                overflow="hidden"
              >
                <Skeleton height="200px" />
                <Skeleton mx={3} height="16px" mt={2} />
                <Skeleton mx={3} width="80%" height="16px" mt={2} />
                <Skeleton mx={3} width="60%" height="16px" mt={2} />
                <Skeleton mx={3} width="40%" height="16px" mt={2} />
                <Skeleton mx={3} width="30%" height="16px" mt={5} />
                <Flex justifyContent="space-between" alignItems="center">
                  <Skeleton mx={3} width="60%" height="16px" mt={2} />
                  <Skeleton
                    mx={3}
                    width="30px"
                    height="30px"
                    borderRadius="50%"
                    mt={2}
                  />
                </Flex>
              </Box>
            ))}
          {isFetchingNextPage &&
            Array.from({ length: 6 }).map((_, index) => (
              <Box
                key={index}
                padding="5px"
                border="1px solid #e2e8f0"
                minW="360px"
                borderRadius="0.375rem"
                overflow="hidden"
                _after={{ flex: "auto", content: '""' }}
              >
                <Skeleton height="200px" />
                <Skeleton mx={3} height="16px" mt={2} />
                <Skeleton mx={3} width="80%" height="16px" mt={2} />
                <Skeleton mx={3} width="60%" height="16px" mt={2} />
                <Skeleton mx={3} width="40%" height="16px" mt={2} />
                <Skeleton mx={3} width="30%" height="16px" mt={5} />
                <Flex justifyContent="space-between" alignItems="center">
                  <Skeleton mx={3} width="60%" height="16px" mt={2} />
                  <Skeleton
                    mx={3}
                    width="30px"
                    height="30px"
                    borderRadius="50%"
                    mt={2}
                  />
                </Flex>
              </Box>
            ))}
        </SimpleGrid>
      </Box>

      <div ref={elementRef} style={{ height: "20px", width: "100%" }}></div>
    </Box>
  );
}

export default Explore;
