import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"; // Import useSelector
import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Heading,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { AuthState } from "../redux/slices/authSlice";

interface StatsCardProps {
  title: string;
  stat: string;
  icon: React.ReactNode;
}

function StatsCard(props: StatsCardProps) {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"sm"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      rounded={"lg"}
    >
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={"medium"} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={"auto"}
          color={useColorModeValue("gray.800", "gray.200")}
          alignContent={"center"}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

export default function StatisticBlock() {
  const selectedCity = useSelector(
    (state: { auth: AuthState }) => state.auth.selectedCity
  );

  useEffect(() => {
    localStorage.setItem("selectedCity", selectedCity);
  }, [selectedCity]);

  return (
    <Box mx={"auto"}>
      <Flex alignItems="center">
        <Heading
          fontSize={"2xl"}
          fontWeight={"600"}
          marginRight={5}
          marginBottom={5}
        >
          Let's explore the housing index at
          <Text as={"span"} color={"green.500"}>
            {" "}
            {selectedCity}
          </Text>
        </Heading>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard title={"Users"} stat={"5,000"} icon={<CopyIcon />} />
        <StatsCard title={"Servers"} stat={"1,000"} icon={<CopyIcon />} />
        <StatsCard title={"Datacenters"} stat={"7"} icon={<CopyIcon />} />
      </SimpleGrid>
    </Box>
  );
}
